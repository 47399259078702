import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import axios from 'axios'
import qs from 'qs'
import {
	formatTime
} from './utils/Format'


axios.defaults.baseURL = 'https://uefa.getyippi.com/apis';
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true; //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
		if(response.data.code==403){
			router.replace({
				path: '/login',
				//登录成功后跳入浏览的当前页面
				query: {redirect: router.currentRoute.fullPath}
			})

		}
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.response.status) {
      return Promise.reject(error.response);
    }
  }
);



Vue.prototype.$axios = axios
Vue.prototype.$qs = qs
Vue.filter('formatTime', function(time){
	return formatTime(time)
})
Vue.filter('formatImage', function(url){
	if((url.indexOf('http://')!=-1) || (url.indexOf('https://')!=-1)){
		return url
	}else{
		return 'https://uefa.getyippi.com/' + url
	}
})

// 引入富文本组件
import QuillEditor from 'vue-quill-editor'
// 引入富文本组件样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(QuillEditor)

Vue.config.productionTip = false
new Vue({
	router,
	data: function(){
		return {
			URL:'https://uefa.getyippi.com'
		}
	},
	store,
	render: h => h(App)
}).$mount('#app')
