import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  token:window.localStorage.getItem('token')
  },
  mutations: {
	  setToken(state, text){
		  state.token = text
		  window.localStorage.setItem('token', text)
	  },
	  logout(state){
		  state.token = ''
		  window.localStorage.removeItem('token')
	  }
  },
  actions: {
  },
  modules: {
  }
})
