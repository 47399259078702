<template>
  <div id="app">
	  <header class="mainheader">
		  <div class="left">Mini Program JDT 2021</div>
		  <div class="right">{{time}}</div>
	  </header>
	  <router-view></router-view>
  </div>
</template>

<script>
import {formatTime} from './utils/Format.js'
export default {
  name: 'app',
  components: {
  },
  data () {
	  return {
		  time:''
	  }
  },
  mounted() {
	  setInterval(()=>{
		this.time = formatTime()
	  },1000)
  },
  methods: {
  }
}
</script>

<style>
	body{
		padding: 0;
		margin: 0;
	}
	#app{
		padding: 0;
		margin: 0;
	}
	.mainheader{
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #8e8e93;
		padding: 20px 30px;
		background-color: rgb(58,58,60);
		color: #fff;
	}
	.mainheader .left{
		font-size: 30px;
		font-weight: bold;
	}
	.mainheader .right{
		font-size: 16px;
	}
	.el-table{
		border-radius: 6px;
	}
	.el-icon-delete{
		color: red;
	}
	
	.Confirmbtn{
		color: #000!important;
		background-color: #fbb600!important;
		border-color: #fbb600!important;
	}
	.Cancelbtn{
		color: #fcbe00!important;
		background-color: #ffffff00!important;
		border-color: #fcbe00!important;
	}
	
	.avatar-uploader .el-upload {
		border: 1px dashed #8e8e93;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	
	.avatar-uploader .el-upload:hover {
		border-color: #f3f3f3;
	}
	
	.avatar {
		width: 100px;
		height: 100px;
		display: block;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 80px!important;
		text-align: center;
	}
	.uploadspan{
		position: absolute;
		left: calc(50% - 31.5px);
		color: #8e8e93;
		font-size: 12px;
		bottom:20px;
	}
	.uploadhead{
		padding: 20px 0 10px 0;
		line-height: 2;
	}
	.uploadtitle{
		font-size: 14px;
		color: #fff;
	}
	.uploadlabel{
		color: #636368;
		font-size: 12px;
	}
	
	.tal input{
		text-align: left!important;
	}
	.el-table{
		background-color: #2c2c2e!important;
	}
	.el-table--enable-row-hover .el-table__body tr:hover>td{
		background-color: #222!important;
	}
	.el-input input{
		color: #d5d5d5;
	}
	.mt10 {
		margin-top: 10px;
	}
	.el-popconfirm{
		background-color: #fff;
	}
</style>
