import Vue from 'vue'
import { Button, Input, Menu, MenuItem, Table, TableColumn, Pagination, Switch, MessageBox, Message, Select, Option, Upload, InputNumber, Image, DatePicker, Loading, Avatar, Dialog, Popconfirm } from 'element-ui'

Vue.use(Button)
Vue.use(Input)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Option)
Vue.use(Upload)
Vue.use(InputNumber)
Vue.use(Image)
Vue.use(Avatar)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(Popconfirm)

Vue.use(Loading.directive);

Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service;