import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: () => import( /* webpackChunkName: "home" */ '../views/home.vue'),
		meta: {
			requiresAuth: true
		},
		redirect: '/matches',
		children: [{
				path: '/matches',
				name: 'matches',
				component: () => import( /* webpackChunkName: "matches" */ '../views/matches.vue')
			},{
				path: '/addmatches',
				name: 'addmatches',
				component: () => import( /* webpackChunkName: "addmatches" */ '../views/addmatches.vue')
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import( /* webpackChunkName: "login" */ '../views/login.vue')
	}
]

const router = new VueRouter({
	routes
})


router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!localStorage.getItem("token")) {
			next({
				path: '/login',
				query: {
					redirect: to.fullPath
				}
			})
		} else {
			next()
		}
	} else {
		next()
	}
})

export default router
