const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const formatTime = function (strtime) {
	var time = strtime ? new Date(Number(strtime+'000')) : new Date;
	return time.getDate() + ' ' + month[time.getMonth()] + ' ' + time.getFullYear() + ', ' + formatAMPM(time)
}
const formatAMPM = function(date) {
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime = hours + ':' + minutes + '' + ampm;
	return strTime;
}

module.exports = {
	formatTime: formatTime
}